<template>
  <div>
    <div class="title">考勤规则</div>

    <el-divider></el-divider>

    <div class="table_title">使用说明</div>

    <div class="tip">
      1、考勤组管理，即按部门或员工分组，并为每个组设置不同的考勤方式、出勤日、考勤规则等；<br />
      2、支持设置多个考勤组，灵活组合员工的出勤方式，如双休、单休、大小周或排班等。<br />
      3、不考勤方案，适用于企业中不需要考勤的人员，即不需要打卡或记录请假等，如企业高层。<br />
      4、不打卡方案，适用于企业中不需要打卡，但需要记录请假等的人员，如长期外勤人员。<br />
      5、若员工未加入任何考勤组，核算时，将按“企业默认考勤组”进行核算。
    </div>

    <div style="position: relative; width: 100%;">
      <div
        class="custom_button no_select add_btn"
        @click="$router.push({ name: 'AttendanceRulesEditor' })"
      >
        添加考勤组
      </div>
    </div>

    <!-- table -->
    <el-table
      :data="tableData"
      :header-cell-style="tableHeaderStyle"
      :cell-style="tableCellStyle"
      style="width: 100%;"
    >
      <el-table-column prop="name" label="考勤组名称"> </el-table-column>
      <el-table-column prop="type" label="考勤组类型"> </el-table-column>
      <el-table-column prop="day" label="出勤日"> </el-table-column>
      <el-table-column prop="time" label="出勤时间"> </el-table-column>
      <el-table-column prop="rule" label="核算规则"> </el-table-column>
      <el-table-column prop="range" label="适用范围"> </el-table-column>
      <el-table-column label="操作">
        <template v-slot:default="scope">
          <div class="operations">
            <div
              @click="
                $router.push({
                  name: 'AttendanceRulesEditor',
                  params: { id: scope.row.id },
                })
              "
            >
              修改
            </div>
            <div style="color: #636a77;">删除</div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      layout="prev, pager, next"
      :total="page.total"
      :page-size="page.size"
      :current-page.sync="page.page"
      @current-change="loadData"
    >
    </el-pagination>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      tableData: [],

      page: {
        size: 15,
        page: 1,
        total: 0,
      },

      typeOptions: {
        1: "标准工时制",
        2: "综合工时制",
      },

      tableHeaderStyle: {
        backgroundColor: "#F5F8FA",
        textAlign: "center",
      },
      tableCellStyle: {
        textAlign: "center",
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions("attendance", ["getPunchRulesList"]),

    loadData() {
      let loading = this.$loading();
      this.getPunchRulesList({
        page: this.page.page,
      })
        .then((res) => {
          console.log(res);
          this.tableData = res.data.data.list.map((item) => {
            item.type = this.typeOptions[item.work_hours_type];
            return item;
          });
          this.page.total = res.data.data.total;
          loading.close();
        })
        .catch((e) => {
          loading.close();
          this.$message.error(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.tip {
  color: #495060;
  line-height: 16px;
  font-size: 12px;
  margin-bottom: 40px;
}

.add_btn {
  position: absolute;
  right: 0;
  bottom: 20px;
}

.el-table {
  margin-bottom: 60px;
}

.operations {
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    font-size: 14px;
    color: #406bff;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 13px;
    }
  }
}
</style>
